// App.js
import React from 'react';
import './style.css';
import logo from './assets/flowpro-emblem 1.png';
import videoFile from './assets/FlowPro-Sponsor-R.mp4';

function App() {
  return (
    <>
      <nav>
        <div className="logobox">
          <div className="logo">
            <img src={logo} alt="FlowPro Logo" />
          </div>
        </div>
        <div className="button">
          <button>Product da CodeXFlow</button>
        </div>
      </nav>
      <div className="mainbody">
        <h1>
          Streamlining the <span>flow</span> of your
          <br />
          <span>organization</span> just got <span>very easier</span>.
        </h1>
        <h3>We are launching on December 31, 2024!</h3>
        <video autoPlay loop muted>
          <source src={videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="footer">Copyright © 2023 - 2024 FlowPro</div>
    </>
  );
}

export default App;
